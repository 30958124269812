import { render, staticRenderFns } from "./SearchClass.vue?vue&type=template&id=f708884e&scoped=true"
import script from "./SearchClass.vue?vue&type=script&lang=js"
export * from "./SearchClass.vue?vue&type=script&lang=js"
import style0 from "./SearchClass.vue?vue&type=style&index=0&id=f708884e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f708884e",
  null
  
)

export default component.exports